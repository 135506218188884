function GatheringCalculator() {
    return (
        <>
            <div className="row">
                <div className="col">
                    <h1>채집 속도 계산기</h1>
                    <hr/>
                    <p>
                        시간당 채집 속도를 계산해보세요!
                    </p>
                </div>
            </div>
        </>
    );
}

export default GatheringCalculator;